import React, { useCallback, useContext, useState, useMemo } from "react";
import { Box, Button, Typography, Divider } from "@material-ui/core";
import {
  IContactInfo,
  ContactInfoForm,
  CheckoutStepper,
  DesktopPopupModal,
  NotificationBanner,
  BannerSeverity,
  Icon,
  IconName,
  TravelerSelectStep,
  ButtonWrap,
  emailRegex,
  phoneRegex,
  ActionButton,
  ActionLink,
  frequentFlyerRegex as rewardsRegex,
  PolicyBanner,
  ApprovalBanner,
  ApprovalReasonTextarea,
  formatDateTimeWithTimeZone,
  TreesConfirmation,
  InformationalModal as TreesModal,
  TripPurposePanel,
} from "halifax";
import { DO_NOT_APPLY_REWARDS_KEY } from "@capone/common";
import { RouteComponentProps } from "react-router";
import { ProductError } from "@b2bportal/purchase-api";
import { useExperimentIsVariant } from "@capone/experiments";
import clsx from "clsx";
import {
  ADD_CONTACT_INFO,
  CarsBookErrorEnum,
  CAR_SAVE_AND_CONTINUE_CLICKED,
  ITravelerStepErrors,
  InvalidEnum,
  ConnectionResultEnum,
  CorpSessionInfo,
  CorpCarPriceQuote,
  PaymentError,
} from "redmond";

import { setSubmitForApprovalFailure } from "../../../actions/actions";
import { DesktopCarBookWorkflowConnectorProps } from "./container";
import { ClientContext } from "../../../../../App";
import { AGENT_FEE, CarBookSummaryPanel } from "../../";
import * as t from "./textConstants";
import { CarBookPassengerSelection } from "../../CarBookPassengerSelection";
import { PATH_HOME, PATH_BOOK_CONFIRMATION } from "../../../../../utils/paths";
import { PriceBreakdown } from "../../PriceBreakdownCard";
import { trackEvent } from "../../../../../api/v1/analytics/trackEvent";
import {
  AVAILABLE,
  getExperimentVariant,
  useExperiments,
  TREES_MODAL_EXPERIMENT,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  CREDIT_OFFER_STACKING_V1,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
} from "../../../../../context/experiments";
import { fetchCustomerDetails } from "../../../../../api/v1/customer/fetchCustomerDetails";
import submitForApproval from "../../../../../api/v1/book/book-flow/submitForApproval";
import { HertzLoyaltyForm } from "../../HertzRewardsForm";
import { CarShopDetails } from "../../../../shop/components/CarShopDetails";
import { TravelOfferSelection } from "../../TravelOfferSelection";
import { PaymentCard } from "../../PaymentCard";
import "./styles.scss";

export interface IDesktopCarBookWorkflowProps
  extends RouteComponentProps,
    DesktopCarBookWorkflowConnectorProps {}

export const CorpDesktopCarBookWorkflow = ({
  setContactInfo,
  driverAge,
  history,
  combinedBookingSteps,
  schedulePriceQuote,
  isBookingValid,
  isContactStepComplete,
  isTravelerStepComplete,
  priceQuote,
  priceDifferenceAcknowledged,
  confirmation,
  scheduleBook,
  allStepsCompleted,
  email,
  phoneNumber,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  priceQuoteErrors,
  setPriceQuote,
  isHertzProvider,
  hertzLoyaltyRewardsNumber,
  confirmationDetailsErrors,
  setSubmitForApproval,
  selectedVehicle,
  driver,
  paymentInfo,
  carAvailabilityRequest,
  fetchApplicableTravelWalletItems,
  isTravelWalletPaymentOnly,
  fetchTravelWalletCreditHistory,
  useV1PurchaseFlow,
  setTripPurpose,
}: IDesktopCarBookWorkflowProps): JSX.Element => {
  const corporateTravel = selectedVehicle?.corporateTravel;
  const isInPolicy =
    // TODO(replat): add back after BE work is done
    //   priceQuote
    // ? (priceQuote.vehAvail as CorpVehicleAvail).corporateTravel.policyCompliance
    //     .isInPolicy
    // :
    corporateTravel?.policyCompliance.isInPolicy ?? true;

  const [approvalRequestReason, setApprovalRequestReason] = useState<
    string | undefined
  >();

  const { isAutoApprovalEnabled, policies } = useContext(ClientContext);

  const maxPricePerDay =
    policies?.cars.policies[0]?.maxPricePerDay ?? undefined;

  const onBookCar = () => {
    priceDifferenceAcknowledged || !!priceQuote
      ? scheduleBook(isAgentPortal ? AGENT_FEE : 0, approvalRequestReason)
      : schedulePriceQuote(history, isAgentPortal ? AGENT_FEE : 0, undefined);
  };

  const onSubmitForApprovalFailure = () =>
    setSubmitForApprovalFailure({
      Invalid: InvalidEnum.Missing,
      ConnectionResult: ConnectionResultEnum.Invalid,
    });

  const onSubmitForApproval = async () => {
    if (selectedVehicle && driver) {
      const request = {
        approvalReason: approvalRequestReason || "",
        cancellationPolicy: selectedVehicle.cancellationPolicy,
        contactEmail: contactInfo?.email || "",
        driver,
        dropOff: selectedVehicle.dropOff,
        paymentInfo: paymentInfo?.payments[0],
        pickUp: selectedVehicle.pickUp,
        policyCompliance:
          // (priceQuote?.vehAvail as CorpVehicleAvail).corporateTravel
          //   .policyCompliance ||
          selectedVehicle?.corporateTravel?.policyCompliance,
        rentalRate: priceQuote?.vehAvail?.rentalRate,
        shopRequest: {
          opaqueShopRequestContext: selectedVehicle.opaqueShopRequestContext,
          opaqueShopRequestVehicle: selectedVehicle.opaqueShopRequestVehicle,
        },
        supplier: (priceQuote as CorpCarPriceQuote)?.dataList?.suppliers[0],
        totalPrice: selectedVehicle.totalCharge.fiat,
        vehicle: selectedVehicle.vehicle,
        vendor: (priceQuote as CorpCarPriceQuote)?.dataList?.vendors[0],
        availabilityRequest: carAvailabilityRequest,
        requestedAt: formatDateTimeWithTimeZone(new Date()),
        isApprovalRequired: !isAutoApprovalEnabled && !isApprovalsV2Enabled,
      };

      try {
        if (isAutoApprovalEnabled || isApprovalsV2Enabled) {
          const requestId = await submitForApproval(request);
          requestId ? onBookCar() : onSubmitForApprovalFailure();
        } else {
          setSubmitForApproval(request);
        }
      } catch (error) {
        console.error(error);
        onSubmitForApprovalFailure();
      }
    } else {
      onSubmitForApprovalFailure();
    }
  };

  const handleReasonChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>, isCharLimitError: boolean) => {
      setApprovalRequestReason(e.target.value);
      if (isCharLimitError) {
        trackEvent({
          eventName: "approval_reason_char_limit_error",
          properties: {
            funnel: "cars",
          },
        });
      }
    },
    []
  );

  const expState = useExperiments();

  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const isTravelWalletCreditsExperiment = useExperimentIsVariant(
    TRAVEL_WALLET_CREDITS_EXPERIMENT,
    AVAILABLE
  );

  const isTravelCreditHistoryExperiment = useExperimentIsVariant(
    TRAVEL_CREDIT_HISTORY_EXPERIMENT,
    AVAILABLE
  );

  const isCreditAndOfferStackingExperimentV1 = useExperimentIsVariant(
    CREDIT_OFFER_STACKING_V1,
    AVAILABLE
  );

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const isTripPurposeXpEnabled = useExperimentIsVariant(
    "corp-trip-purpose",
    AVAILABLE
  );

  const isShoppingCartGroundEnabled = useExperimentIsVariant(
    "c1-shopping-cart-ground-booking",
    AVAILABLE
  );

  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal } = clientContext;
  const isFirstStepCompletedInCheckout =
    isContactStepComplete && isTravelerStepComplete;
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [showErrors, setShowErrors] = useState<ITravelerStepErrors>({
    phone: false,
    email: false,
    travelerSelect: false,
    loyaltyNumber: false,
  });

  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: phoneNumber || "",
    email: email || sessionInfo?.userInfo?.email || "",
  });
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    React.useState<boolean>(true);
  const [disableEditTravelerInfo, setDisableEditTravelerInfo] = useState(false);
  const [groundDetailsModalOpen, setGroundDetailsModalOpen] = useState(false);
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  React.useEffect(() => {
    if (confirmation) {
      history.push(PATH_BOOK_CONFIRMATION);
    }
  }, [confirmation]);

  React.useEffect(() => {
    if (priceQuoteErrors.length > 0) {
      setDisableEditTravelerInfo(false);
    }
  }, [priceQuoteErrors]);

  React.useEffect(() => {
    if (
      confirmationDetailsErrors.some((error) => {
        return useV1PurchaseFlow
          ? (error as ProductError)?.value?.value?.msg ===
              CarsBookErrorEnum.LoyaltyNameMismatch
          : (error as PaymentError)?.msg ===
              CarsBookErrorEnum.LoyaltyNameMismatch;
      })
    ) {
      setDisableEditTravelerInfo(false);
    }
  }, [confirmationDetailsErrors]);

  React.useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
    isTravelWalletCreditsExperiment && fetchApplicableTravelWalletItems();
    isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();
  }, []);

  const isUnderAge = (driverAge || 0) < 25;

  const renderLogo = (
    <ButtonWrap
      className="logo"
      onClick={() => {
        history.push(PATH_HOME);
      }}
    >
      {clientContext.logo}
    </ButtonWrap>
  );

  return (
    <Box
      className={clsx(
        "corp-desktop-car-book-workflow-root",
        "home-screen-redesign"
      )}
    >
      <CheckoutStepper
        steps={combinedBookingSteps}
        headerCopy={t.BOOK_BUTTON_TEXT}
        subHeaderCopy={t.REQUIRED_DETAILS_TEXT}
        logo={renderLogo}
        className={clsx("b2b", "combined-step")}
      />
      <Box className="desktop-car-book-workflow-container">
        <Box className={clsx("checkout-template-column", "left")}>
          <Box
            className={clsx("checkout-template-card-content-container", "car")}
          >
            <CarBookSummaryPanel hideButtons />
          </Box>

          {isTripPurposeXpEnabled && (
            <Box
              className={clsx(
                "checkout-template-card-content-container",
                "trip-purpose"
              )}
            >
              <TripPurposePanel setTripPurpose={setTripPurpose} />
            </Box>
          )}

          {isTravelWalletCreditsExperiment &&
            !isCreditAndOfferStackingExperimentV1 && <TravelOfferSelection />}
          <Box className="checkout-template-card-content-container traveler">
            <Box className={clsx("checkout-template-description")}>
              <Typography
                variant="h2"
                className={clsx("checkout-template-title")}
              >
                {t.TRAVELER_INFO_TITLE}
              </Typography>
            </Box>
            <Divider className="traveler-info-container-divider" />
            <CarBookPassengerSelection
              disabled={disableEditTravelerInfo}
              progress={travelerWorkflowStep}
              setProgress={setTravelerWorkflowStep}
              combinedStep
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              saveButtonClicked={saveButtonClicked}
              hiddenTravelerIds={
                (sessionInfo as CorpSessionInfo)?.corporateInfo
                  .hiddenTravelerIds ?? []
              }
            />
            <Divider className="traveler-info-container-divider" />
            <ContactInfoForm
              className={clsx({
                "hide-padding": isHertzProvider,
              })}
              disabled={disableEditTravelerInfo}
              title={t.CONTACT_INFO_TITLE_NO_STEP}
              subtitle={t.CONTACT_INFO_SUBTITLE}
              contactInfo={contactInfo}
              onContactInfoChange={(info: IContactInfo) => {
                setContact(info);
                setContactInfo(info.email, info.phoneNumber);
                if (
                  info.email &&
                  info.phoneNumber &&
                  emailRegex.test(info.email) &&
                  phoneRegex.test(info.phoneNumber)
                ) {
                  trackEvent({
                    eventName: ADD_CONTACT_INFO,
                    properties: {},
                  });
                }
              }}
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              loading={customerDetailsLoading}
            />
            {isHertzProvider && (
              <>
                <Divider className="hertz-loyalty-rewards-divider" />
                <HertzLoyaltyForm
                  disabled={disableEditTravelerInfo}
                  showErrors={showErrors}
                  setShowErrors={setShowErrors}
                />
              </>
            )}

            {!disableEditTravelerInfo && (
              <ActionButton
                className={clsx(
                  "traveler-select-workflow-button",
                  "traveler-step-cta"
                )}
                message="Save & Continue"
                onClick={() => {
                  const hasValidLoyaltyNumber =
                    isHertzProvider && hertzLoyaltyRewardsNumber
                      ? rewardsRegex.test(hertzLoyaltyRewardsNumber)
                      : true;

                  if (isFirstStepCompletedInCheckout && hasValidLoyaltyNumber) {
                    schedulePriceQuote(
                      history,
                      isAgentPortal ? AGENT_FEE : 0,
                      true
                    );
                    setDisableEditTravelerInfo(true);
                    setShowErrors({
                      phone: false,
                      email: false,
                      travelerSelect: false,
                      loyaltyNumber: false,
                    });
                    trackEvent({
                      eventName: CAR_SAVE_AND_CONTINUE_CLICKED,
                      properties: {
                        has_form_errors: false,
                      },
                    });
                  } else {
                    setShowErrors({
                      phone:
                        !contactInfo ||
                        !phoneRegex.test(contactInfo.phoneNumber),
                      email:
                        !contactInfo || !emailRegex.test(contactInfo.email),
                      travelerSelect: !isTravelerStepComplete,
                      loyaltyNumber: !hasValidLoyaltyNumber,
                    });
                    if (!saveButtonClicked) {
                      setSaveButtonClicked(true);
                    }
                    trackEvent({
                      eventName: CAR_SAVE_AND_CONTINUE_CLICKED,
                      properties: {
                        has_form_errors: true,
                        traveler_error: !isTravelerStepComplete,
                        email_error:
                          !contactInfo || !emailRegex.test(contactInfo.email),
                        phone_error:
                          !contactInfo ||
                          !phoneRegex.test(contactInfo.phoneNumber),
                        loyaltyNumber: !hasValidLoyaltyNumber,
                      },
                    });
                  }
                }}
                defaultStyle="h4r-primary"
              />
            )}
            {disableEditTravelerInfo && (
              <ActionLink
                className={clsx("traveler-step-cta", "b2b")}
                content={
                  <Box className="edit-traveler-link">
                    <Typography variant="body1">
                      Edit Traveler Information
                    </Typography>
                    <Icon
                      aria-hidden
                      className="icon-end"
                      name={IconName.B2BEditPencil}
                      ariaLabel=""
                    />
                  </Box>
                }
                onClick={() => {
                  setPriceQuote(null);
                  setDisableEditTravelerInfo(false);
                  setSelectedPaymentMethodId({
                    paymentMethodId: "",
                    accountId: undefined,
                  });
                  setSelectedRewardsAccountReferenceId(
                    DO_NOT_APPLY_REWARDS_KEY
                  );
                }}
              />
            )}
            <Box className={clsx("contact-info-helper-container")}>
              <Typography
                variant="body2"
                className={clsx("contact-info-helper-text")}
              >
                {t.CONTACT_INFO_HELPER_TEXT}
              </Typography>
            </Box>
          </Box>
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "credit"
            )}
          >
            <PaymentCard
              disabled={
                !isFirstStepCompletedInCheckout ||
                !disableEditTravelerInfo ||
                (!isCreditAndOfferStackingExperimentV1 &&
                  isTravelWalletPaymentOnly)
              }
              className={clsx({ "less-padding": isTreesModalExperiment })}
            />
            {isTreesModalExperiment && (
              <TreesModal
                image={TreesConfirmation}
                header={t.TREES_MODAL_HEADER}
                title={t.TREES_MODAL_TITLE}
                onClose={() => setTreeModalOpen(false)}
                subtitle={t.TREES_MODAL_SUBTITLE}
                imageLocation={t.TREES_MODAL_LOCATION}
                icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
                openModal={treeModalOpen}
                setOpenModal={setTreeModalOpen}
                modalLinkCopy={t.TREES_MODAL_CTA_TEXT}
                modalButtonCopyStartIcon={
                  <Icon className="trees-icon" name={IconName.TreesIcon} />
                }
                boldedModalLinkCopy={t.TREES_BOLDED_MODAL_CTA_TEXT}
              />
            )}
          </Box>
        </Box>
        <Box className={clsx("checkout-template-column", "right")}>
          <Box className="checkout-template-column-right-wrapper">
            {isAgentPortal && (
              <ActionLink
                showTappableArea
                className="b2b"
                content={
                  <Box className="agent-details-modal-trigger">
                    <Icon className="info-icon" name={IconName.InfoCircle} />
                    <Typography className="trigger-label">
                      {t.DETAILS_MODAL_TRIGGER_LABEL}
                    </Typography>
                  </Box>
                }
                onClick={() => setGroundDetailsModalOpen(true)}
              />
            )}
            <Box className="car-book-right-content">
              <PolicyBanner isInPolicy={isInPolicy} />
              <Box className="payment-break-down-container">
                <Box
                  className={clsx(
                    "checkout-template-card-content-container",
                    "payment-break-down"
                  )}
                >
                  <PriceBreakdown />

                  {isUnderAge && (
                    <Box className="underage-banner-container">
                      <NotificationBanner
                        className="underage-banner"
                        label={t.UNDER_25_WARNING}
                        severity={BannerSeverity.INFO}
                        icon={
                          <Icon
                            className="banner-icon"
                            name={IconName.InfoCircleFill}
                          />
                        }
                      />
                    </Box>
                  )}

                  {!isInPolicy && (
                    <>
                      <ApprovalReasonTextarea
                        value={approvalRequestReason ?? ""}
                        className="approval-reason"
                        onChange={handleReasonChange}
                        corporateTravel={corporateTravel}
                        productType="car"
                        limit={maxPricePerDay}
                      />
                      <ApprovalBanner
                        isApprovalRequired={
                          isApprovalsV2Enabled
                            ? policies?.settings &&
                              policies.settings.isApprovalRequired
                            : !isAutoApprovalEnabled
                        }
                        isApprovalsV2Enabled={isApprovalsV2Enabled}
                      />
                    </>
                  )}
                  <Box className="confirm-book-button-container">
                    <Button
                      className="confirm-book-button"
                      disabled={
                        !isBookingValid ||
                        !allStepsCompleted ||
                        (!isInPolicy && !approvalRequestReason)
                      }
                      onClick={() => {
                        // TODO: CA-2198 -
                        // Once approval v2 is turned on and trip request is gone, only call onBookHotel if isApprovalsV2Enabled is true
                        // If shopping cart is enabled, we pass the out of policy reason to the book car call
                        isInPolicy ||
                        (isApprovalsV2Enabled && isShoppingCartGroundEnabled)
                          ? onBookCar()
                          : onSubmitForApproval();
                      }}
                    >
                      {isInPolicy ||
                      isAutoApprovalEnabled ||
                      isApprovalsV2Enabled
                        ? t.BOOK_BUTTON_TEXT
                        : t.SUBMIT_FOR_APPROVAL_TEXT}
                    </Button>
                  </Box>
                </Box>
              </Box>
              {groundDetailsModalOpen && (
                <DesktopPopupModal
                  className="agent-ground-details-modal"
                  invisibleBackdrop={false}
                  onClose={() => setGroundDetailsModalOpen(false)}
                  open={groundDetailsModalOpen}
                >
                  <CarShopDetails hideBanner hidePaymentSection />
                </DesktopPopupModal>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
